import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Pricing from '../components/pricing'

const PricingPage = () => {
  const lang = "en";
  return (
    <Layout lang={lang}>
      <SEO title="Pricing" />
      <Pricing lang={lang}/>
    </Layout>
  )
}

export default PricingPage
